import React from 'react';

const YourTeam = ({ members }) => {
  if (!members || members.length === 0) return '';
  return (
    <div className="page-header mt-5">
      <div className="container">
        <div className="row">
          <div className="card">
            <div className="card-body">
              <h5 className="font-weight-bolder">{sent ? 'Invite Sent' : 'Invite Received'}</h5>
              <hr class="dark horizontal my-4" />
              <div className="table-responsive p-0">
                <table className="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"></th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"></th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"></th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourTeam;
