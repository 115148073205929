import React from 'react';
import moment from 'moment';
const InviteDetail = ({ invites, sent, changeRequestStatus }) => {
  if (!invites || invites.length === 0) return '';

  const formatDate = (timeStampDate) => {
    return moment(timeStampDate).format('Do MMM YYYY, h:mm a');
  };

  const showInviteAction = (inviteId) => {
    if (!sent) {
      return (
        <>
          <button
            className="btn btn-success text-white btn-sm"
            onClick={() => changeRequestStatus({ inviteId, status: 'APPROVED' })}
          >
            <i className="fa fa-check me-sm-1"></i> Accept
          </button>
          &nbsp;
          <button
            className="btn btn-danger text-white btn-sm"
            onClick={() => changeRequestStatus({ inviteId, status: 'REJECTED' })}
          >
            <i className="fa fa-xmark me-sm-1"></i> Reject
          </button>
        </>
      );
    } else {
      return (
        <button
          className="btn btn-danger text-white btn-sm"
          onClick={() => changeRequestStatus({ inviteId, status: 'CANCELLED' })}
        >
          <i className="fa fa-xmark me-sm-1"></i> Cancel
        </button>
      );
    }
  };
  return (
    <div className="card card-plain h-100 shadow-lg">
      <div className="card-body">
        <h5 className="font-weight-bolder">{sent ? 'Invite Sent' : 'Invite Received'}</h5>
        <hr class="dark horizontal my-4" />
        <div className="table-responsive p-0">
          <table className="table table-bordered align-items-center mb-0">
            <thead>
              <tr>
                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Project
                </th>
                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Sender
                </th>
                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Date
                </th>
                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {invites.map((invite) => (
                <tr key={invite.uuid}>
                  <td className="align-middle text-center">{invite.projectName}</td>
                  <td className="align-middle text-center">
                    {invite.senderName} ({invite.senderUsername})
                  </td>
                  <td className="align-middle text-center">{formatDate(invite.dateCreated)}</td>
                  <td className="align-middle text-center">{showInviteAction(invite.uuid)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default InviteDetail;
