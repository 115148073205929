import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import * as actions from './redux/actions';
import InviteTeamMember from './InviteTeamMember';

export class TeamMember extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };
  state = {
    currentPage: 1,
    perPage: 10,
  };
  async componentDidMount() {
    const { currentPage, perPage } = this.state;
    const { home, actions } = this.props;
    const { project } = home;
    const { uuid } = project ? project : {};
    await actions.teamMemberList({ projectId: uuid, page: currentPage - 1, size: perPage });
  }

  renderTableRows = () => {
    const { home } = this.props;
    const { teamMemberList } = home;
    const contentArray = teamMemberList && teamMemberList.content ? teamMemberList.content : [];

    return contentArray.map((member) => (
      <tr key={member.id}>
        <td className="align-middle text-center">
          <p className="text-sm font-weight-bold">{member.firstName}</p>
        </td>
        <td className="align-middle text-center">
          <p className="text-sm font-weight-bold">{member.lastName}</p>
        </td>
        <td className="align-middle text-center">
          <p className="text-sm font-weight-bold">{member.username}</p>
        </td>
        <td className="align-middle text-center">
          <button
            className="btn btn-outline-danger btn-sm"
            onClick={() => this.handleDelete(member.uuid)}
          >
            <i class="material-icons text-sm position-relative">delete</i>
            Remove
          </button>
        </td>
      </tr>
    ));
  };

  handleDelete = async (uuid) => {
    const { props, actions } = this.props;
    const { params } = props.match;
    const { projectUuid } = params;
    const { currentPage, perPage } = this.state;

    const result = await Swal.fire({
      icon: 'warning',
      title: `Are you sure about deleting this team member?`,
      showCancelButton: true,
      confirmButtonText: 'Delete',
    });

    if (result.value) {
      try {
        await actions.removeTeamMemeber({ uuid, projectId: projectUuid });
        await actions.teamMemberList({
          projectId: projectUuid,
          page: currentPage - 1,
          size: perPage,
        });
      } catch (error) {
        console.error('Error deleting team member:', error);
      }
    }
  };

  render() {
    const { home } = this.props;
    const { teamMemberList } = home;
    const { content } = teamMemberList ? teamMemberList : {};
    return (
      <div className="container-fluid py-4">
        <div className="row">
          <div className="col-12">
            <div className="card my-4">
              <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                <div className="bg-primary border-radius-lg opacity-8 pt-4 pb-3">
                  <h6 className="text-white text-capitalize ps-3">Team Member's List</h6>
                </div>
              </div>
              <div className="card-body px-0 pb-2">
                {content && content.length > 0 ? (
                  <div className="table-responsive p-0">
                    <table className="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            First Name
                          </th>
                          <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                            Last Name
                          </th>
                          <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Username
                          </th>
                          <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>{this.renderTableRows()}</tbody>
                    </table>
                  </div>
                ) : (
                  <p className="text-center mt-3">
                    There are no team members for this project yet!
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <InviteTeamMember />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamMember);
