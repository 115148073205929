import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import Select from 'react-select';
import CustomLoader from '../common/CustomLoader';
import { profileValidationSchema } from '../common/utils/form-validation';
import CountryOptions from '../common/utils/country-data.json';
import CustomSelect from '../../common/CustomSelect';

const ClientProfileForm = ({
  initialValues,
  onSubmit,
  updateProfilePending,
  handleCountryChange,
  onDeleteProfile,
}) => {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={profileValidationSchema}
      onSubmit={onSubmit}
    >
      {({ values, touched, errors, isSubmitting, setFieldValue, setFieldTouched }) => (
        <Form encType="multipart/form-data" className="profile-form">
          <div className="mb-3">
            <label htmlFor="businessName" className="form-label">
              Business Name
            </label>
            <Field
              type="text"
              name="businessName"
              placeholder="Business Name"
              value={values.businessName}
              className={`form-control ${
                touched.businessName && errors.businessName ? 'is-invalid' : ''
              }`}
            />
            {touched.businessName && errors.businessName && (
              <div className="invalid-feedback">{errors.businessName}</div>
            )}
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="firstName" className="form-label">
                First Name
              </label>
              <Field
                type="text"
                name="firstName"
                placeholder="First Name"
                value={values.firstName}
                className={`form-control ${
                  touched.firstName && errors.firstName ? 'is-invalid' : ''
                }`}
              />
              {touched.firstName && errors.firstName && (
                <div className="invalid-feedback">{errors.firstName}</div>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="lastName" className="form-label">
                Last Name
              </label>
              <Field
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={values.lastName}
                className={`form-control ${
                  touched.lastName && errors.lastName ? 'is-invalid' : ''
                }`}
              />
              {touched.lastName && errors.lastName && (
                <div className="invalid-feedback">{errors.lastName}</div>
              )}
            </div>
          </div>

          <div className="mb-3">
            <label htmlFor="country" className="form-label">
              Country
            </label>
            <CustomSelect
              name="country"
              isMulti={false}
              value={values.country}
              options={CountryOptions}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
            />
            <ErrorMessage name="country" component="div" className="invalid-feedback" />
          </div>
          <hr className="form-divider" />
          <div className="text-center">
            <div className="d-flex justify-content-between">
              <button
                type="button"
                className="btn btn-outline-danger btn-md mt-4 mb-2 ml-5"
                onClick={onDeleteProfile}
                style={{ whiteSpace: 'nowrap' }}
              >
                <i className="material-icons text-lg position-relative">delete</i>
                Delete
              </button>
              <button
                type="submit"
                disabled={isSubmitting || updateProfilePending}
                className="btn btn-success btn-md text-white mt-4 mb-2"
                style={{ whiteSpace: 'nowrap' }}
              >
                <i className="material-icons text-lg position-relative">save</i>
                Update
                <CustomLoader loading={isSubmitting || updateProfilePending} />
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ClientProfileForm;
