import React from 'react';
import cookie from 'react-cookies';
import InviteDetail from './InviteDetail';

const Invites = ({ pendingInvites, changeRequestStatus }) => {
  if (!pendingInvites || !pendingInvites.length) return '';
  const username = cookie.load('username');
  const sentInvite = [];
  const receivedInvite = [];
  pendingInvites.forEach((invite) => {
    if (invite.senderUsername === username) {
      sentInvite.push(invite);
    } else {
      receivedInvite.push(invite);
    }
  });

  return (
    <div className="page-header mt-5">
      <div className="container">
        <div className="row">
          <InviteDetail
            invites={receivedInvite}
            sent={false}
            changeRequestStatus={changeRequestStatus}
          />
          <InviteDetail
            invites={sentInvite}
            sent={true}
            changeRequestStatus={changeRequestStatus}
          />
        </div>
      </div>
    </div>
  );
};

export default Invites;
