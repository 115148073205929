import axios from 'axios';
import {
  HOME_CHANGE_REQUEST_STATUS_BEGIN,
  HOME_CHANGE_REQUEST_STATUS_SUCCESS,
  HOME_CHANGE_REQUEST_STATUS_FAILURE,
  HOME_CHANGE_REQUEST_STATUS_DISMISS_ERROR,
} from './constants';
import { builderAuthHeader } from '../../common/utils/auth';

export function changeRequestStatus(args = {}) {
  return (dispatch) => {
    dispatch({
      type: HOME_CHANGE_REQUEST_STATUS_BEGIN,
    });
    const { inviteId, status } = args;
    const config = builderAuthHeader();
    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.post(
        process.env.REACT_APP_ORGANIZATION_ENGINE_URL +
          `team-member/${inviteId}/change-invitation-status/${status}`,
        {},
        config,
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: HOME_CHANGE_REQUEST_STATUS_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        (err) => {
          dispatch({
            type: HOME_CHANGE_REQUEST_STATUS_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissChangeRequestStatusError() {
  return {
    type: HOME_CHANGE_REQUEST_STATUS_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_CHANGE_REQUEST_STATUS_BEGIN:
      return {
        ...state,
        changeRequestStatusPending: true,
        changeRequestStatusError: null,
      };

    case HOME_CHANGE_REQUEST_STATUS_SUCCESS:
      return {
        ...state,
        changeRequestStatusPending: false,
        changeRequestStatusError: null,
      };

    case HOME_CHANGE_REQUEST_STATUS_FAILURE:
      return {
        ...state,
        changeRequestStatusPending: false,
        changeRequestStatusError: action.data.error,
      };

    case HOME_CHANGE_REQUEST_STATUS_DISMISS_ERROR:
      return {
        ...state,
        changeRequestStatusError: null,
      };

    default:
      return state;
  }
}
