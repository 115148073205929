import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import Layout from './Layout';
import Invites from './teams/PendingRequest';
import OrganizationList from './teams/OrganizationList';
import YourTeam from './teams/YourTeam';

export class Team extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { actions } = this.props;
    //Load Pending Invites
    actions.loadPendingInvite();
    //Load list of organization user is member
    //Load list of member of your organization
  }

  render() {
    const { actions, home } = this.props;
    const { pendingInvites, orgClient } = home;
    const { changeRequestStatus } = actions;
    return (
      <Layout currentPage="Teams">
        <Invites pendingInvites={pendingInvites} changeRequestStatus={changeRequestStatus} />
        <OrganizationList />
        <YourTeam members={orgClient.members} />
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Team);
